import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation Login($token: String!) {
    login(token: $token) {
      success
      token
    }
  }
`;

const TEST_LOGIN = gql`
  mutation TestLogin($email: String!) {
    testLogin(email: $email) {
      success
      token
    }
  }
`;

const SAVE_MERGED_DEALS = gql`
  mutation SaveMergedDeals($mergedDeals: [OutputDealsInput!]) {
    saveOutputDeals(input: $mergedDeals) {
      success
      message
    }
  }
`;

const DELETE_MERGED_DEALS = gql`
  mutation DeleteDeals($deleteMergedDealsInput: [DeleteMergedDealsInput!]) {
    deleteDeals(input: $deleteMergedDealsInput) {
      success
      message
    }
  }
`;

const SAVE_MERGED_COMPANIES = gql`
  mutation MergeCompanies($mergedCompanies: MergeCompaniesInput!) {
    mergeCompanies(input: $mergedCompanies) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_COMPANIES = gql`
  mutation MatchCompanies($matchCompanies: MatchCompaniesInput!) {
    matchCompanies(input: $matchCompanies) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_ISSUER = gql`
  mutation MatchIssuer($matchIssuerInput: MatchIssuerInput!) {
    matchIssuer(input: $matchIssuerInput) {
      success
      message
    }
  }
`;

const DELETE_MERGED_COMPANIES = gql`
  mutation DeleteMergedCompanies(
    $team: Team!
    $companies: [DeleteMergedCompaniesInput!]
  ) {
    deleteMergedCompanies(team: $team, companies: $companies) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_COMPANIES = gql`
  mutation DeleteMatchedCompanies(
    $matchToDelete: DeleteMatchedCompaniesInput!
  ) {
    deleteMatchedCompanies(input: $matchToDelete) {
      success
      message
    }
  }
`;

const DELETE_MANUALLY_MATCHED_ISSUER = gql`
  mutation DeleteManuallyMatchedIssuer(
    $matchToDelete: DeleteManuallyMatchedIssuerInput
  ) {
    deleteManuallyMatchedIssuer(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_AFFINITY = gql`
  mutation MatchAffinity($matchAffinityInput: MatchAffinityInput) {
    matchAffinity(input: $matchAffinityInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_AFFINITY = gql`
  mutation DeleteManuallyMatchedAffinity(
    $matchToDelete: DeleteManuallyMatchedAffinityInput
  ) {
    deleteManuallyMatchedAffinity(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_NS_COMPANY = gql`
  mutation MatchNetsuiteCompany(
    $matchNetsuiteCompanyInput: MatchNetsuiteCompanyInput!
  ) {
    matchNetsuiteCompany(input: $matchNetsuiteCompanyInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_NS_COMPANY = gql`
  mutation DeleteManuallyMatchedNsCompany(
    $matchToDelete: DeleteManuallyMatchedNsCompanyInput
  ) {
    deleteManuallyMatchedNsCompany(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_MATCHED_NS_FUND = gql`
  mutation MatchNetsuiteFud($matchNetsuiteFundInput: MatchNetsuiteFundInput!) {
    matchNetsuiteFund(input: $matchNetsuiteFundInput) {
      success
      message
    }
  }
`;

const DELETE_MATCHED_NS_FUND = gql`
  mutation DeleteManuallyMatchedNsFund(
    $matchToDelete: DeleteManuallyMatchedNsFundInput
  ) {
    deleteManuallyMatchedNsFund(input: $matchToDelete) {
      success
      message
    }
  }
`;

const SAVE_TAX_TRANSACTION_OVERRIDE = gql`
  mutation SaveTaxTransactionOverride($input: [SaveTaxTransactionInput!]) {
    saveTaxTransactionOverride(input: $input) {
      success
      message
    }
  }
`;

const SAVE_VALUATION_COMPANY_CONCLUSION = gql`
  mutation SaveValuationCompanyConclusion(
    $input: SaveValuationCompanyConclusionInput!
  ) {
    saveValuationCompanyConclusion(input: $input) {
      success
      message
    }
  }
`;

const SAVE_VALUATION_COMPANY_UPDATES = gql`
  mutation SaveValuationCompanyUpdates(
    $input: SaveValuationCompanyUpdatesInput!
  ) {
    saveValuationCompanyUpdates(input: $input) {
      success
      message
    }
  }
`;

const gqlMutations = {
  LOGIN,
  TEST_LOGIN,
  SAVE_MERGED_DEALS,
  DELETE_MERGED_DEALS,
  SAVE_MERGED_COMPANIES,
  DELETE_MERGED_COMPANIES,
  SAVE_MATCHED_COMPANIES,
  DELETE_MATCHED_COMPANIES,
  SAVE_MATCHED_ISSUER,
  DELETE_MANUALLY_MATCHED_ISSUER,
  SAVE_MATCHED_AFFINITY,
  DELETE_MATCHED_AFFINITY,
  SAVE_MATCHED_NS_COMPANY,
  DELETE_MATCHED_NS_COMPANY,
  SAVE_MATCHED_NS_FUND,
  DELETE_MATCHED_NS_FUND,
  SAVE_TAX_TRANSACTION_OVERRIDE,
  SAVE_VALUATION_COMPANY_CONCLUSION,
  SAVE_VALUATION_COMPANY_UPDATES,
};

export default gqlMutations;
