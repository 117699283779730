import { decodeJwt } from "lib/utils";

export const getUserEmail = () => {
  const token = localStorage.getItem("authToken");

  if (!token) {
    return "";
  }

  // @ts-ignore
  return decodeJwt(token.toString()).email;
};

export const getUserRoles = () => {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem("authToken");

    if (!token) {
      return "";
    }

    // @ts-ignore
    return decodeJwt(token.toString()).roles || [];
  } else {
    return "";
  }
};
