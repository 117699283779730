import { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { datadogRum } from "@datadog/browser-rum";

import { PageLoader } from "components/loader";

import gqlMutations from "api/gql-mutations";
import logger from "lib/logger";
import { getUserEmail } from "lib/auth";
import { isTestMode } from "lib/utils";

import styles from "styles/Home.module.scss";

export default function Home() {
  const [testEmail, setTestEmail] = useState("");

  let router = useRouter();

  const [loading, setLoading] = useState(false);

  const [login] = useMutation(gqlMutations.LOGIN);
  const [testLogin] = useMutation(gqlMutations.TEST_LOGIN);

  const onSuccess = async (credentialResponse: any) => {
    if (credentialResponse.credential) {
      setLoading(true);

      const response = await login({
        variables: { token: credentialResponse.credential },
      });
      setLoading(false);

      if (response?.data?.login?.success) {
        logger.debug("Successful login.");

        localStorage.setItem("authToken", response.data.login?.token);

        datadogRum.startSessionReplayRecording();

        const userEmail = getUserEmail();
        datadogRum.setUser({ email: userEmail });

        router.push("/dashboard#match-transactions");
      }
    }
  };

  const onTestLogin = async () => {
    const response = await testLogin({ variables: { email: testEmail } });

    if (response?.data?.testLogin?.success) {
      localStorage.setItem("authToken", response.data.testLogin?.token);
      router.push("/home");
    }
  };

  const onError = () => {
    logger.error("Login failed.");
  };

  if (isTestMode()) {
    return (
      <div>
        <input
          id="testEmail"
          type="text"
          onChange={(e) => setTestEmail(e.target.value)}
        />
        <button id="testLogin" onClick={onTestLogin}>
          Login
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.authPanel}>
          <div className={styles.title}>
            Welcome to <br />
            General Catalyst <br />
            Data Manager
          </div>
          <GoogleLogin onSuccess={onSuccess} onError={onError} />
        </div>
      </main>
      {loading && <PageLoader />}
    </div>
  );
}
